body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 background: #F6F1EB !important;
}
#root{
  max-width: 700px;
  min-height: 100vh;
  margin: 0 auto;
  background: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Basker;
  src: url(./assets/Fonts/Baskerville.ttc);
  font-display: swap;
}
@font-face {
  font-family: Whitney;
  src: url(./assets/Fonts/whitney-medium.otf);
  font-display: swap;
}

/* slide Notice */
.react-multi-carousel-list{
  height: 100px;
  /* overflow: scroll !important; */
}
.react-multiple-carousel__arrow--left,.react-multiple-carousel__arrow--left::before {
  content: '' !important;
  background-image: url(./assets/izquierda.svg) !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  margin-left: -10px;
  /* background-position-x: -15px;
  background-position-y: -10px; */
}
.react-multiple-carousel__arrow--right,.react-multiple-carousel__arrow--right::before {
  content: '' !important;
  background-image: url(./assets/derecha.svg) !important;
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  margin-right: -15px;
   /* transform: rotate(90deg); */
  /* background-position-x: 50px; */
  /* background-position-y: -10px; */
}
.react-multiple-carousel__arrow {
  background: transparent !important;
}


/* slide image */
.react-slideshow-container{
  z-index: 10;
}
.indicators{
  position: absolute;
  left: 0;
  right: 0;
  margin-top: -20px !important;
  z-index: 100;
}

.react-slideshow-container + div.indicators > .each-slideshow-indicator.active{
  background: grey !important;
}
.react-slideshow-container + div.indicators > .each-slideshow-indicator{
  width: 10px !important;
  height: 10px !important;
}